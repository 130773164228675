<script setup lang="ts">
import {CToaster, CToast, CToastHeader, CToastBody, CToastClose} from "@coreui/vue";
const store = useToastsStore();
const { toasts } = storeToRefs(store);
</script>

<template>
  <CToaster
    placement="top-end"
    visible
  >
    <CToast
      v-for="toast in toasts"
      :key="toast.content"
      :color="toast.color"
      :class="toast.class"
      :delay="toast.delay || 3000"
      visible
    >
      <CToastHeader
        v-if="toast.title"
        close-button
      >
        <span class="me-auto fw-bold">{{ toast.title }}</span>
      </CToastHeader>
      <div class="d-flex">
        <CToastBody>
          {{ toast.content }}
        </CToastBody>
        <CToastClose
          v-if="!toast.title"
          class="me-2 m-auto"
          white
        />
      </div>
    </CToast>
  </CToaster>
</template>