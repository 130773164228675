interface Toast {
    title?: string,
    content: string,
    color?: string,
    class?: string,
    delay?: number
}

export const useToastsStore = defineStore('toasts', () => {
    const toasts = ref<Toast[]>([]);

    const addToast = (toast: Toast) => {
        toasts.value.push(toast)
    };

    const addInfo = (text: string, title?: string) => {
        addToast({
            title,
            content: text,
            color: "info",
            class: "text-white"
        })
    };

    const addSuccess = (text: string, title?: string) => {
        addToast({
            title,
            content: text,
            color: "success",
            class: "text-white"
        })
    };

    const addWarning = (text: string, title?: string) => {
        addToast({
            title,
            content: text,
            color: "warning",
            class: "text-dark"
        })
    };

    const addError = (text: string, title?: string) => {
        addToast({
            title,
            content: text,
            color: "danger",
            class: "text-white"
        })
    };

    const addPrimary = (text: string, title?: string) => {
        addToast({
            title,
            content: text,
            color: "primary",
            class: "text-white"
        })
    };

    const addSecondary = (text: string, title?: string) => {
        addToast({
            title,
            content: text,
            color: "secondary",
            class: "text-white"
        })
    };

    const addLight = (text: string, title?: string) => {
        addToast({
            title,
            content: text,
            color: "light",
            class: "text-dark"
        })
    };

    return {
        toasts,
        addInfo,
        addSuccess,
        addWarning,
        addError,
        addPrimary,
        addSecondary,
        addLight
    };
});
